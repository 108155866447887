<template>
    <div>
        <b-row class="justify-content-center">
            <b-col md="6">
                <div class="d-block bg-light-primary text-center p-4 mb-5 rounded text-white h4">Appel entrant du {{ $route.params.phone }}</div>
            </b-col>
        </b-row>
        <div v-if="concats === false" class="d-block text-center mt-5">
            <i class="fas fa-spin fa-spinner fa-3x text-primary"></i>
        </div>      
        <b-row class="justify-content-center"  v-else-if="concats.length === 0" >
            <b-col md="6">
                <div class="d-block bg-light-warning text-center p-4 mb-5 rounded h6">Désolé, nous n'avons pas trouvé de correspondance.</div>
            </b-col>
        </b-row>        
        <b-card v-else>
            <b-table-simple hover>    
                <b-thead>                
                    <b-th>Titre</b-th>
                    <b-th>Telephone 1</b-th>
                    <b-th>Telephone 2</b-th>
                    <b-th>CP</b-th>
                    <b-th>Ville</b-th>
                    <b-th>Creation</b-th>                
                    <b-th>Modification</b-th>                
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(c, i) in concats" :key="i">        
                        <b-td><a  :href="c.url" class="text-bolder">{{ c.titre }}</a></b-td>
                        <b-td><Telephone v-model="c.telephone_1" :edit="false" /></b-td>
                        <b-td><Telephone v-model="c.telephone_2" :edit="false" /></b-td>
                        <b-td>{{ c.cp }}</b-td>
                        <b-td>{{ c.ville }}</b-td>
                        <b-td>{{ $api.timestampToDate(c.date_create) }}</b-td>
                        <b-td>{{ $api.timestampToDate(c.date_update) }}</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-card>        
    </div>
  </template>
  <script>  
  import Telephone from '@/components/Telephone';
  export default {
    name: "ClientAppel",
    components: {   
        Telephone   
    },
    data(){      
      return{
        concats: false,
      }
    },
    watch:{      
    },
    computed:{      
    },
    beforeMount() {    
    },
    mounted() {  
        this.$api.ajax('/client/appel?phone='+this.$route.params.phone, null, res => {
            if(res.status === true){                
                this.concats = res.data;
            }
        })    
    },
    methods: {
    }
  }
  </script>
  <style>
  
  </style>
  